import React from 'react';
import Analytics from './components/Analytics';
import Cards from './components/Cards';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import Edlimited from './components/Edlimited';
import Cardsed from './components/Cardsed';
import Analytics2 from './components/Analytics2';
import Medition from './components/Medition';
import Divisor from './components/Divisor';
import Cards2 from './components/Cards2';


function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Divisor />
      <Medition />
      <Edlimited />
      <Cards2 />
      <Analytics />
      <Cardsed />
      <Newsletter />
      <Analytics2 />
      <Cards />
      <Footer />
    </div>
  );
}

export default App;
